import {
  find, isNil, includes, replace, toLower,
} from 'lodash';
import oddsConverter from '@/services/helpers/odds-converter';

export const getPriceBySelection = (selections, targetSelection, oddsFormat) => {
  const foundSelection = find(selections,
    (selection) => selection.selectionType.selectionCode === targetSelection.selectionType.selectionCode
    && selection.selectionType.params.HALF_X === targetSelection.selectionType.params.HALF_X
    && selection.selectionType.params.PERIOD_X === targetSelection.selectionType.params.PERIOD_X
    && selection.selectionType.params.LOWER_BOUND === targetSelection.selectionType.params.LOWER_BOUND
    && selection.selectionType.params.TEAM === targetSelection.selectionType.params.TEAM
    && selection.selectionType.params.UPPER_BOUND === targetSelection.selectionType.params.UPPER_BOUND
    && selection.selectionType.params.MARGIN === targetSelection.selectionType.params.MARGIN
    && selection.selectionType.params.CORRECT_SCORE_AWAY === targetSelection.selectionType.params.CORRECT_SCORE_AWAY
    && selection.selectionType.params.CORRECT_SCORE_HOME === targetSelection.selectionType.params.CORRECT_SCORE_HOME
    && selection.selectionType.params.GOALS_X === targetSelection.selectionType.params.GOALS_X);
  if (!foundSelection || isNil(foundSelection?.price?.decimalValue)) return '-';
  const americanOdd = foundSelection?.price?.originalFormattedValue;
  if (americanOdd && oddsFormat === 'american') return `${americanOdd.signIsPlus ? '+' : '-'}${americanOdd.value}`;
  if (oddsFormat === 'probability') {
    const probability = parseFloat((foundSelection.price.probability + foundSelection.price.bias) * 100).toFixed(1);
    return (probability % 1 > 0) ? `${probability}%` : `${Math.round(probability)}%`;
  }
  return oddsConverter.getMappedOddValue(foundSelection.price, oddsFormat);
};

export const setTeamNames = (selectionLabel, homeTeam, awayTeam) => {
  let replacedLabel = toLower(selectionLabel);
  if (includes(replacedLabel, 'home') && replacedLabel !== 'home run') {
    replacedLabel = replace(replacedLabel, /home/g, homeTeam.name);
  }
  if (includes(replacedLabel, 'away')) {
    replacedLabel = replace(replacedLabel, /away/g, awayTeam.name);
  }

  return replacedLabel;
};
